<!--
 * @Author: mulingyuer
 * @Date: 2021-07-07 17:11:16
 * @LastEditTime: 2021-07-08 16:19:27
 * @LastEditors: mulingyuer
 * @Description: 证书卡片
 * @FilePath: \saas-admin-vue\src\modules\cert-manage\components\add-edit\PreviewCard.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="preview-card" :style="{backgroundColor:data.bg_color||'#6698ff'}">
    <div class="content">
      <div class="head">
        <div class="icon">
          <img v-if="data.icon" :src="data.icon">
          <img v-else src="../../assets/images/cert-card-icon.png">
        </div>
        <div class="title-group">
          <span class="cert-id">ID：188888888</span>
          <h2 class="title">{{data.name||"会员证书"}}</h2>
          <p class="subtitle">{{data.prize_name||"年度最佳销售精英"}}</p>
        </div>
      </div>
      <div class="body">
        <div class="body-item">
          <h3 class="title">某某某单位</h3>
          <p class="subtitle">颁发单位</p>
        </div>
        <div class="body-item">
          <h3 class="title">2021-03-22</h3>
          <p class="subtitle">颁发时间</p>
        </div>
        <div class="body-item">
          <h3 class="title">2021-05-20</h3>
          <p class="subtitle">有效期至</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    //数据
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-card {
  width: 335px;
  height: 157px;
  color: #fff;
  background-image: url("../../assets/images/cert-card-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  padding: 16px;
  .content {
    .head {
      display: flex;
      align-items: center;
      position: relative;
      .cert-id {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
        line-height: 17px;
        margin-right: 5px;
      }
      .icon {
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        margin-right: 12px;
        img {
          width: 100%;
          height: 100%;
          vertical-align: top;
          object-fit: contain;
        }
      }
      .title-group {
        min-width: 0;
        flex-grow: 1;
        .title {
          font-size: 16px;
          line-height: 22px;
          font-weight: normal;
        }
        .subtitle {
          margin-top: 2px;
          font-size: 18px;
          font-weight: bold;
          line-height: 25px;
        }
      }
    }
    .body {
      display: flex;
      margin-top: 37px;
      .body-item {
        width: 0;
        flex-grow: 1;
        text-align: center;
        position: relative;
        & + .body-item::before {
          content: "";
          position: absolute;
          top: 5px;
          bottom: 1px;
          left: 0;
          border-left: 1px solid #e9e9e9;
        }
        .title {
          font-size: 13px;
          line-height: 18px;
          font-weight: bold;
        }
        .subtitle {
          margin-top: 3px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}
</style>
