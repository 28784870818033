/*
 * @Author: mulingyuer
 * @Date: 2021-06-30 11:50:11
 * @LastEditTime: 2021-07-12 10:23:03
 * @LastEditors: mulingyuer
 * @Description: 工具函数
 * @FilePath: \saas-admin-vue\src\base\components\MyKonva\utils\tool.js
 * 怎么可能会有bug！！！
 */

/**
 * @description: 将base64转换为文件对象
 * @param {*} dataUrl base64
 * @param {*} fileName 文件名
 * @Date: 2021-06-30 14:33:47
 * @Author: mulingyuer
 */
export function dataURLtoFile(dataUrl, fileName) {
  const arr = dataUrl.split(";base64,"); //[data:image/png,xxx]
  const mime = arr[0].replace("data:", ""); //mime后端识别用的文件格式数据
  const fileType = mime.split("/").pop(); //获取文件格式
  const bstr = atob(arr[1]); //base64解码
  //创建Unicode编码的数组对象，每个值都是Unicode
  const u8arr = new Uint8Array(bstr.split("").map(str => str.charCodeAt(0)));
  return new File([u8arr], `${fileName ?? "file"}.${fileType}`, { type: mime });
}


/**
 * @description: 判断某个对象是否存在对应的属性
 * @param {*} target 检测的对象
 * @param {*} key 属性名
 * @Date: 2021-07-12 10:22:00
 * @Author: mulingyuer
 */
export function hasOwnProperty(target, key) {
  return Object.prototype.hasOwnProperty.call(target, key);
}