<template>
  <div class="right-form-item right-image-item">
    <div class="right-form-item-head">
      <el-checkbox v-model="form.visible" size="small" class="open" @change="onOpenChange">{{form.title}}
      </el-checkbox>
      <el-button v-if="showDelete" size="small" class="delete" type="text" @click="onItemDelete">删除
      </el-button>
    </div>
    <div class="right-form-item-body">
<!--      <single-media-wall v-model="form.src" :width="100" :height="100" :show-mask="false" />-->
<!--      <el-image :src="form.src" style="width: 100px; height: 100px;" fit="contain" />-->
      <options-image :src="form.src" hide-delete />
    </div>
  </div>
</template>

<script>
  // import SingleMediaWall from "@/modules/common/components/SingleMediaWall";
  import OptionsImage from "@/base/components/Media/OptionsImage";
  export default {
    name: "RightImageItem",
    components: {OptionsImage},
    // components: {SingleMediaWall},
    props: {
      //数据
      data: {
        type: Object,
        required: true,
      },
      //数据更新事件
      onUpdate: {
        type: Function,
        default: () => {},
      },
      //删除事件
      onDelete: {
        type: Function,
        default: () => {},
      },
      //是否显示删除按钮
      showDelete: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        //表单数据
        form: {
          visible: true,
          src: "",
        },
      }
    },
    computed: {
      //组件id
      itemId({ data }) {
        return data.attrs.id;
      },
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        const { attrs } = this.data;
        Object.assign(this.form, attrs);
      },
      //更新数据
      myUpdate(attrs = {}) {
        this.onUpdate({ id: this.itemId, attrs });
      },
      //启用状态变化事件
      onOpenChange(status) {
        this.myUpdate({ visible: status });
      },
      // onSrcChange() {
      //   this.myUpdate({src: this.form.src});
      // },
      //删除事件
      onItemDelete() {
        if (this.showDelete) this.onDelete(this.data);
      },
    },
  }
</script>

<style lang="scss" scoped>
  .right-form-item {
    margin-bottom: 24px;
    .right-form-item-head {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .open {
        min-width: 0;
        flex-grow: 1;
        ::v-deep.el-checkbox__label {
          color: #606266;
        }
      }
      .delete {
        flex-shrink: 0;
        margin-left: 15px;
        padding: 0;
      }
    }
    .right-form-item-body {
      .body-item {
        display: flex;
        align-items: center;
        & + .body-item {
          margin-top: 10px;
        }
      }
    }
  }
</style>
