/*
 * @Author: mulingyuer
 * @Date: 2021-07-08 17:00:58
 * @LastEditTime: 2021-07-09 18:24:05
 * @LastEditors: mulingyuer
 * @Description: 新增&编辑
 * @FilePath: \saas-admin-vue\src\modules\cert-manage\api\add-edit.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";
import upload from "@/base/utils/upload4";

//保存
export const uploadFile = data => {
  return upload({
    configApi: "/admin/admin/media/requestUpload",
    method: "post",
    data,
  })
};

//证书模板数据
export const templateData = data => {
  return api({
    url: "/admin/cert/cert/systemCertTemplate",
    method: "post",
    data,
  })
};

//保存
export const save = data => {
  return api({
    url: "/admin/cert/cert/save",
    method: "post",
    data,
  })
};

//保存
export const detail = data => {
  return api({
    url: "/admin/cert/cert/detail",
    method: "post",
    data,
  })
};
