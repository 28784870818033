<!--
 * @Author: mulingyuer
 * @Date: 2021-06-23 16:26:38
 * @LastEditTime: 2021-07-08 18:35:17
 * @LastEditors: mulingyuer
 * @Description: 设计
 * @FilePath: \saas-admin-vue\src\modules\cert-manage\components\add-edit\Design.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="design">
    <div class="content" :style="{
    width, height
  }" @click="onDesignClick">
<div v-if="!imgSrc" class="add">
    <i class="el-icon-plus icon"></i>
    </div>
    <div v-else class="img-wrap">
    <img class="view" :src="fixImageUrl(imgSrc)">
    <div class="view-info">{{info || '编辑证书'}}</div>
</div>
</div>
</div>
</template>

<script>
export default {
  name: 'cert-design-enter',
  props: {
    //预览图
    preview: {
      type: String,
      required: true,
    },
    info: String,
    width: String,
    height: String
  },
  data() {
    return {};
  },
  computed: {
    imgSrc: {
      get() {
        return this.preview;
      },
      set(val) {
        this.$emit("update:preview", val);
      },
    },
  },
  methods: {
    //点击事件
    onDesignClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.design {
  .content {
    width: 200px;
    height: 200px;
    .add,
    .img-wrap {
      height: 100%;
      position: relative;
      overflow: hidden;
      border: 1px dashed rgb(220, 223, 230);
      cursor: pointer;
      &:hover {
        border-color: #3576ff;
        .icon {
          color: #3576ff;
        }
        .view-info {
          opacity: 1;
          transform: translateY(0);
        }
      }
      &:active {
        opacity: 0.7;
      }
    }
    .add {
      display: flex;
      .icon {
        margin: auto;
        font-size: 25px;
        color: #606266;
      }
    }
    .img-wrap {
      .view {
        width: 100%;
        height: 100%;
        object-fit: contain;
        vertical-align: top;
      }
      .view-info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        line-height: 1;
        padding: 8px 5px;
        text-align: center;
        opacity: 0;
        transform: translateY(100%);
        transition: all 0.25s;
      }
    }
  }
}
</style>
