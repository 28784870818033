<!--
 * @Author: mulingyuer
 * @Date: 2021-06-29 15:27:08
 * @LastEditTime: 2021-08-20 17:39:45
 * @LastEditors: mulingyuer
 * @Description: 右侧表单组件
 * @FilePath: \super-admin-vue\src\base\components\MyKonva\components\right\RightFormItem.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="right-form-item" :class="{active}" :style="{
      backgroundColor: active ? '#f5f6f7' : ''
      }">
    <div class="right-form-item-head">
      <el-checkbox v-model="form.visible" size="small" class="open" @change="onOpenChange">{{form.title}}
      </el-checkbox>
      <el-button v-if="showDelete" size="small" class="delete" type="text" @click="onItemDelete">删除
      </el-button>
      <!-- 点击选中元素，并移到最上层 -->
      <el-button v-if="custom && form.visible" size="small" class="delete" type="text" @click="onChoose">选中这个元素</el-button>
    </div>
    <div class="right-form-item-body">
      <div v-if="showValueInput" class="body-item">
        <el-input v-model="form.text" size="small" ref="rightInput" @input="onTextInput"
          @keydown.native="onInputKeydown"></el-input>
      </div>
      <div class="body-item">
        <el-select v-if="!custom" class="font-family-select" size="small" v-model="form.fontFamily" placeholder="请选择字体"
          @change="onFontFamilyChange">
          <el-option v-for="item in fontFamilyArr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-radio-group v-if="custom" size="small" v-model="form.align" @change="onTextAlignChange">
          <el-radio-button v-for="align in alignArr" :key="align.value" :label="align.value">{{align.label}}</el-radio-button>
        </el-radio-group>
        <el-select class="font-style-select" size="small" v-model="form.fontStyle" placeholder="请选择字体粗细"
          @change="onFontStyle">
          <el-option @click="onFontStyle(item.value)" v-for="item in fontStyleArr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-color-picker v-if="!custom" class="color" size="small" v-model="form.fill" @change="onFontColorChange">
        </el-color-picker>
      </div>
      <div v-if="custom" class="body-item">
<!--        <el-radio-group v-if="custom" size="small" v-model="form.align" @change="onTextAlignChange">-->
<!--          <el-radio-button v-for="align in alignArr" :key="align.value" :label="align.value">{{align.label}}</el-radio-button>-->
<!--        </el-radio-group>-->
        <div v-if="custom">{{alignArr.find(a => a.value === form.align).desc}}</div>
      </div>
      <div v-if="!custom" class="body-item">
        <el-slider class="font-family-size" size="small" v-model="form.fontSize" show-input
          @change="onFontSizeChange" :min="1" :max="360">
        </el-slider>
      </div>
    </div>
  </div>
</template>

<script>
import { fontFamilyArr, fontStyleArr, alignArr } from "../../data/right";
export default {
  props: {
    //数据
    data: {
      type: Object,
      required: true,
    },
    //是否显示value表单
    showValueInput: {
      type: Boolean,
      default: true,
    },
    //数据更新事件
    onUpdate: {
      type: Function,
      default: () => {},
    },
    //删除事件
    onDelete: {
      type: Function,
      default: () => {},
    },
    //是否显示删除按钮
    showDelete: {
      type: Boolean,
      default: false,
    },
    custom: Boolean,
    itemData: Object,
    active: Boolean, // 正在编辑
  },
  data() {
    return {
      //表单数据
      form: {
        visible: true,
        text: "",
        fontFamily: "SimSun",
        fontSize: "",
        fill: "#000",
        fontStyle: "bold",
        align: 'center',
        // verticalAlign: 'middle'
      },
      rules: {},
      fontFamilyArr: fontFamilyArr, //字体选项数据
      fontStyleArr: fontStyleArr, //字体粗细选项数据
      alignArr: alignArr, // 字体对齐方式
    };
  },
  computed: {
    //组件id
    itemId({ data }) {
      return data.attrs.id;
    },
  },
  watch: {
    // 'data.attrs.align'(newValue, oldValue) {
    //   // console.log(newValue, oldValue, '----align change from left')
    //   this.form.align = newValue
    // },
    itemData: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log(val, '-------------deep watching')
        this.form.fontStyle = val.attrs.fontStyle
        this.form.align = val.attrs.align
      }
    }
  },
  methods: {
    onChoose(bol) {
      // 告诉外面我要选中这个元素
      this.$emit('choose', bol)
    },
    //初始化数据
    init() {
      const { attrs } = this.data;
      Object.assign(this.form, attrs);

      //自定义字体大小优先
      if (attrs.myFontSize) {
        this.form.fontSize = attrs.myFontSize;
      }
      //默认
      if (!this.form.fontSize) this.form.fontSize = 14;
    },
    //更新数据
    myUpdate(attrs = {}) {
      console.log(attrs, 'update what?')
      this.onUpdate({ id: this.itemId, attrs });
    },
    //启用状态变化事件
    onOpenChange(status) {
      this.myUpdate({ visible: status });
      this.onChoose(!!status) // 启用变量词后自动选中元素
    },
    //文本变化事件
    onTextInput() {
      if (this.showValueInput) {
        this.myUpdate({ text: this.form.text });
      }
    },
    onTextAlignChange() {
      this.myUpdate({
        align: this.form.align
      })
    },
    //字体大小事件
    onFontSizeChange() {
      this.myUpdate({
        fontSize: this.form.fontSize,
        myFontSize: this.form.fontSize,
      });
    },
    //字体格式事件
    onFontFamilyChange() {
      this.myUpdate({ fontFamily: this.form.fontFamily });
    },
    //字体粗细事件
    onFontStyle() {
      this.myUpdate({ fontStyle: this.form.fontStyle });
    },
    //字体颜色事件
    onFontColorChange() {
      this.myUpdate({ fill: this.form.fill });
    },
    //删除事件
    onItemDelete() {
      if (this.showDelete) this.onDelete(this.data);
    },
    //focus选中input
    focus() {
      if (!this.showValueInput) return; //input不显示就不触发

      if (!this.$rightInput) this.$rightInput = this.$refs["rightInput"];
      const domInput = this.$rightInput.$el.querySelector("input");
      domInput && domInput.focus({ preventScroll: true }); //手动聚焦
    },
    //input按键按下事件
    onInputKeydown(event) {
      // event.myKonvaisInput = true; //增加一个标识，myKonva将会忽略此次事件
      event.stopPropagation();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.right-form-item {
  margin-bottom: 24px;

/*  &.active {
    .right-form-item-head .open ::v-deep.el-checkbox__label {
      color: $primary;
    }
  }*/

  .right-form-item-head {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .open {
      min-width: 0;
      flex-grow: 1;
      ::v-deep.el-checkbox__label {
        color: #606266;
      }
    }
    .delete {
      flex-shrink: 0;
      margin-left: 15px;
      padding: 0;
    }
  }
  .right-form-item-body {
    .body-item {
      display: flex;
      align-items: center;
      & + .body-item {
        margin-top: 10px;
      }
      .font-family-select {
        flex-grow: 1;
        width: 150px;
      }
      .font-style-select {
        width: 80px;
        margin: 0 14px;
      }
      .font-family-size {
        width: 100%;
        // padding-left: 10px;
      }
    }
  }
}
</style>
