/*
 * @Author: mulingyuer
 * @Date: 2021-06-29 15:56:32
 * @LastEditTime: 2021-07-01 14:46:39
 * @LastEditors: mulingyuer
 * @Description: 右侧组件数据
 * @FilePath: \super-admin-vue\src\base\components\MyKonva\data\right.js
 * 怎么可能会有bug！！！
 */

//字体
export const fontFamilyArr = [
  { label: "宋体", value: "SimSun" },
  { label: "仿宋", value: "FangSong" },
  { label: "黑体", value: "SimHei" },
  { label: "微软雅黑", value: "Microsoft YaHei" },
];


//字体粗细
export const fontStyleArr = [
  { label: "普通", value: "normal" },
  { label: "加粗", value: "bold" },
];

export const alignArr = [
  {label: "左对齐", value: "left", desc: "文字将以选框的最左侧为原点向右绘制"},
  {label: "居中", value: "center", desc: "文字将以选框的中心点为原点向两侧绘制"},
  {label: "右对齐", value: "right", desc: "文字将以选框的最后侧为原点向左绘制"},
]



