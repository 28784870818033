<!--
 * @Author: mulingyuer
 * @Date: 2021-07-07 16:24:21
 * @LastEditTime: 2021-07-23 15:01:10
 * @LastEditors: mulingyuer
 * @Description: 新增或编辑
 * @FilePath: \saas-admin-vue\src\modules\cert-manage\views\add-edit.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="add-edit">
    <div class="content" v-loading="loading">
      <div class="card-preview">
        <PreviewCard :data="form" />
        <div class="info">卡片预览</div>
      </div>
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="140px"
        class="medium-form add-edit-form" @submit.native.prevent>
        <el-form-item label="证书名称：" prop="name">
          <el-input type="text" v-model="form.name" placeholder="请输入证书名称"></el-input>
        </el-form-item>
        <el-form-item label="奖项名称：" prop="prize_name">
          <el-input type="text" v-model="form.prize_name" placeholder="请输入奖项名称"></el-input>
        </el-form-item>
        <el-form-item label="图标：" prop="icon">
          <el-input v-show="false" type="text" v-model="form.icon"></el-input>
          <ImageItem v-model="form.icon" :width="100" :height="100" />
          <div class="info">
            <i class="el-icon-info"></i>建议200px*200px
          </div>
        </el-form-item>
        <el-form-item label="背景色：" prop="bg_color">
          <el-color-picker v-model="form.bg_color" show-alpha></el-color-picker>
        </el-form-item>
        <el-form-item label="证书固定编号：" prop="cert_number">
          <el-input type="text" v-model="form.cert_number" placeholder="1～9位字母或数字组合"></el-input>
          <div class="info">
            <i class="el-icon-info"></i>证书编号是由固定编号+系统随机编号位数组成。同一个证书ID下，颁发的证书编号唯一
          </div>
        </el-form-item>
        <el-form-item label="证书随机编号位数：" prop="system_number">
          <el-select v-model="form.system_number" placeholder="请选择随机编号位数">
            <el-option v-for="op in systemNumberArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
          <div class="info">
            <i class="el-icon-info"></i>证书编号是由固定编号+系统随机编号位数组成。同一个证书ID下，颁发的证书编号唯一
          </div>
        </el-form-item>
        <el-form-item label="证书设计：" prop="cert_design.preview_image">
          <el-input v-show="false" v-model="form.cert_design.preview_image"></el-input>
          <Design :preview.sync="form.cert_design.preview_image" @click="openMyKonva=true" />
          <div class="info">
            <i class="el-icon-info"></i>上传证书图片或选择证书模版
          </div>
        </el-form-item>
        <el-form-item label="证书有效期：" prop="expire_count">
          <el-input v-model.number="form.expire_count" placeholder="请输入证书有效期">
            <template slot="append">月</template>
          </el-input>
          <div class="info">
            <i class="el-icon-info"></i>证书有效期支持0-1200个月，填写 0 代表永久有效，默认为0
          </div>
        </el-form-item>
        <el-form-item label="颁发单位：" prop="cert_company">
          <el-input type="text" v-model="form.cert_company" placeholder="请输入颁发单位"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <!-- 悬浮按钮 -->
    <fixed-action-bar>
      <el-button v-if="!loading" type="primary" size="medium" :loading="saveLoading"
        @click="onSave('ruleForm')">保存</el-button>
      <el-button size="medium" @click="onCancel">取消</el-button>
    </fixed-action-bar>
    <!-- 媒体库弹窗 -->
    <MediaSelector v-model="openMediaSelector" @select="onSelect" />
    <!-- 证书编辑 -->
    <MyKonva v-model="openMyKonva" :templateData="template_config" :configData.sync="form.cert_design.config"
      :preview.sync="form.cert_design.preview_image" :mobilePreview.sync="form.cert_design.mp_image"
      :uploadApi="uploadFile" :isNewAdd="!isEdit" />
  </div>
</template>

<script>
import FixedActionBar from "@/base/layout/FixedActionBar";
import ImageItem from "@/modules/common/components/ImageItem";
import PreviewCard from "../components/add-edit/PreviewCard";
import Design from "../components/add-edit/Design";
import MediaSelector from "@/modules/common/components/MediaSelector";
import MyKonva from "@/base/components/MyKonva";
import { uploadFile, templateData, save } from "../api/add-edit";
import { detail } from "../api/add-edit";
import cloneDeep from "lodash/cloneDeep";
export default {
  data() {
    return {
      uploadFile, //上传文件的api
      loading: false, //加载中
      form: {
        id: 0, //为0时新增
        name: "", //证书名称
        prize_name: "", //证书奖项名称
        icon: "", //卡片图标
        bg_color: "", //卡片背景色
        cert_number: "", //证书固定编号
        system_number: 5, //证书随机编号位数
        expire_count: 0, //证书有效期
        //证书配置
        cert_design: {
          preview_image: "", //预览图
          mp_image: "", //手机端底图
          config: {}, //配置
        },
        cert_company: "", //颁发单位
      },
      template_config: [], //模板数据
      rules: {
        name: [{ required: true, message: "请输入证书名称", target: "blur" }],
        prize_name: [
          { required: true, message: "请输入奖项名称", target: "blur" },
        ],
        icon: [{ required: true, message: "请选择证书图标", target: "change" }],
        bg_color: [
          { required: true, message: "请选择证书背景色", target: "change" },
        ],
        cert_number: [
          { required: true, message: "请输入证书固定编号", target: "blur" },
          {
            validator: (rule, value, callback) => {
              if (/[a-zA-Z0-9]{1,9}/.test(value)) {
                return callback();
              }
              return callback(new Error("请输入1~9位字母或数字组合"));
            },
            target: "blur",
          },
        ],
        system_number: [
          {
            type: "number",
            required: true,
            message: "请选择证书随机编号位数",
            target: "change",
          },
        ],
        //证书设计
        "cert_design.preview_image": [
          { required: true, message: "请选择证书设计", target: "change" },
        ],
        expire_count: [
          {
            required: true,
            type: "integer",
            min: 0,
            max: 1200,
            message: "请输入证书有效期",
            trigger: "blur",
            transform(value) {
              return value === "" ? 0 : Number(value);
            },
          },
        ],
        cert_company: [
          { required: true, message: "请输入颁发单位", target: "blur" },
        ],
      },
      systemNumberArr: [
        { name: "5", id: 5 },
        { name: "6", id: 6 },
        { name: "7", id: 7 },
        { name: "8", id: 8 },
        { name: "9", id: 9 },
      ],
      openMediaSelector: false, //是否显示图片上传弹窗
      openMyKonva: false, //是否显示证书编辑
      saveLoading: false, //保存中
      editData: {}, //编辑的数据
    };
  },
  computed: {
    //证书id
    id({ $route }) {
      return $route.query.id;
    },
    //是否编辑
    isEdit({ id }) {
      return typeof id !== "undefined";
    },
  },
  //注入
  provide() {
    return {
      //打开媒体库的方法
      openMedia: (callback) => {
        this.openMediaSelector = true;
        this.$designCallback = callback;
      },
    };
  },
  methods: {
    //获取证书模板
    getTemplateData() {
      return new Promise((resolve, reject) => {
        templateData()
          .then((res) => {
            const { data } = res;
            this.template_config = data;
            return resolve(true);
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    //获取编辑的数据
    getDetail() {
      return new Promise((resolve, reject) => {
        const postData = { id: this.id };
        detail(postData)
          .then((res) => {
            const { data } = res;
            this.editData = data;
            this.initForm();
            return resolve(true);
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    //初始化表单
    initForm() {
      Object.keys(this.form).forEach((key) => {
        if (key === "expire_count") {
          const val = this.editData[key] === 0 ? "" : this.editData[key];
          this.form[key] = val;
        } else {
          this.form[key] = this.editData[key];
        }
      });
    },
    //媒体库弹窗回调
    onSelect(data) {
      const item = data[0];
      if (this.$designCallback) {
        this.$designCallback(item.url);
        this.$designCallback = null; //只触发一次，下次重新来
      }
    },
    //获取保存的数据
    getSaveData() {
      const cloneData = cloneDeep(this.form);
      if (cloneData.expire_count === "") {
        cloneData.expire_count = 0;
      }
      return cloneData;
    },
    //取消
    onCancel() {
      this.$router.go(-1);
    },
    //保存
    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const postData = this.getSaveData();
          save(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              this.saveLoading = false;
              this.$router.go(-1);
            })
            .catch(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
  async created() {
    try {
      this.loading = true;
      await this.getTemplateData();
      if (this.isEdit) {
        await this.getDetail();
      }
      this.loading = false;
    } catch (err) {
      this.loading = false;
    }
  },
  beforeRouteEnter(to, from, next) {
    const id = to.query.id;
    if (typeof id !== "undefined") {
      to.meta.title = "编辑";
    } else {
      to.meta.title = "新增";
    }
    next();
  },
  components: {
    FixedActionBar,
    ImageItem,
    PreviewCard,
    Design,
    MediaSelector,
    MyKonva,
  },
};
</script>

<style lang="scss" scoped>
.add-edit {
  .content {
    padding: 20px 0;
    position: relative;
    .card-preview {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1;
      .info {
        margin-top: 15px;
        text-align: center;
        color: #999;
      }
      @media screen and (max-width: 1050px) {
        position: static;
        display: inline-block;
        margin-left: 25px;
        margin-bottom: 25px;
      }
    }
    .add-edit-form {
      .cert-number {
        display: flex;
      }
    }
  }
}
</style>
