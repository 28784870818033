/*
 * @Author: mulingyuer
 * @Date: 2021-06-22 11:15:27
 * @LastEditTime: 2021-08-19 16:08:21
 * @LastEditors: mulingyuer
 * @Description:封装konva
 * @FilePath: \super-admin-vue\src\base\components\MyKonva\utils\my_konva.js
 * 怎么可能会有bug！！！
 */
import Konva from 'konva/lib/Core'
import { Circle } from 'konva/lib/shapes/Circle'
import { Rect } from 'konva/lib/shapes/Rect'
import { Text } from 'konva/lib/shapes/Text'
import { Image as KImage } from 'konva/lib/shapes/Image'
import { Transformer } from 'konva/lib/shapes/Transformer'
import { Line } from 'konva/lib/shapes/Line'
import { Group } from 'konva/lib/Group'
import { hasOwnProperty } from './tool'
import { randomString } from '@/base/utils/tool'

class MyKonva {
  //默认配置
  defaultOptions = {
    data: [], //配置数据
    pixelRatio: 1, //导出的图片倍数
    // 刻度尺
    ruler: {
      size: 25,
      space: 5,
      scale: 1,
    },
    scale: 1,
    custom: false,
    backgroundLoad: () => {}, //背景图加载完毕回调
    onload: () => {}, //初始化完成回调
    shapeClick: () => {}, //图形点击事件
    backgroundClick: () => {}, // 点击图形以外的地方
  }

  constructor(options = {}) {
    this.changeFnDirection() //改变this指向
    this.init(options)
  }

  /**
   * @description:  初始化
   * @param {Object} options 配置对象
   * @Date: 2021-08-19 16:06:11
   * @Author: mulingyuer
   */
  async init(options) {
    this.options = {}
    Object.assign(this.options, this.defaultOptions, options) //合并配置

    if (!this.options.container) throw new Error('容器不存在')

    if (this.options?.json?.stage) {
      //读取json配置
      this.$stage = Konva.Node.create(
        this.options.json.stage,
        this.options.container
      )
      this.$stage.destroyChildren()
    } else {
      //初始化-地基
      this.$stage = new Konva.Stage({
        container: this.options.container,
        width:
          (this.options.width ?? 0) +
          (this.options.custom ? this.options.ruler.size : 0),
        height:
          (this.options.height ?? 0) +
          (this.options.custom ? this.options.ruler.size : 0),
        draggable: this.options.draggable ?? false,
      })
    }

    //地基添加事件
    this.$stage.on('click tap', this.onStageClick.bind(this))

    //初始化图层
    if (this.options?.json?.layer) {
      //读取json
      this.$layer = Konva.Node.create(this.options.json.layer)
      await this.readJSONSpecial() //特殊处理
    } else {
      if (this.utils.getType(this.options.data) !== 'array')
        throw new Error('配置数据必须是一个数组对象')

      this.$layer = new Konva.Layer()

      //初始化图形&图形组
      for (let itemData of this.options.data) {
        await this.drawData(itemData)
      }
    }

    //添加标尺
    this.addRuler()
    //事件委托-监听拖拽
    this.onWatchDraggable()

    //挂载图层
    this.$stage.add(this.$layer)
    //图层渲染
    this.$layer.draw()

    //初始化完成回调
    this.options.onload()
  }

  /**
   * @description: 改变事件的指向
   * @param {*}
   * @Date: 2021-08-19 16:06:03
   * @Author: mulingyuer
   */
  changeFnDirection() {
    const keys = ['onArrowEndEvent', 'onArrowEvent']
    keys.forEach((key) => {
      this[key] = this.utils.bind(this, this[key])
    })
  }

  // 模拟点击舞台上的元素
  activateShape(id) {
    var shape = this.$stage.find('#' + id)[0]
    this.onStageClick({target: shape})
  }

  /**
   * @description: 通用初始化图形数据方法
   * @param {Object} itemData {className,attrs}
   * @Date: 2021-08-19 16:05:29
   * @Author: mulingyuer
   */
  drawData(itemData) {
    return new Promise(async (resolve, reject) => {
      try {
        switch (itemData.className.toLowerCase()) {
          case 'circle':
            const circle = new Circle(itemData.attrs) //创建实例
            this.$layer.add(circle) //将数据插入图层
            break
          case 'image':
            await this.drawImage(itemData)
            break
          case 'text':
            const text = new Text(itemData.attrs)
            this.$layer.add(text)
            break
          case 'line':
            const line = new Line(itemData.attrs)
            this.$layer.add(line)
            break
          default:
            return reject(new Error('该图形暂未支持'))
          // throw new Error("该图形暂未支持");
        }

        return resolve(true)
      } catch (error) {
        return reject(error)
      }
    })
  }

  /**
   * @description: 生成图片
   * @param {Object} itemData {className,attrs}
   * @param {Object} oldImage 旧的图形对象
   * @Date: 2021-08-19 16:03:08
   * @Author: mulingyuer
   */
  drawImage(itemData, oldImage = null) {
    return new Promise((resolve, reject) => {
      try {
        if (oldImage) oldImage.destroy() //旧实例销毁

        console.log(itemData.attrs.src, 'draw image')

        if (!itemData.attrs.src) {
          resolve(true)
          return
        }

        const isClean = itemData.attrs.src.indexOf('?') === -1
        KImage.fromURL(
          isClean
            ? `${itemData.attrs.src}?r=${randomString(8)}`
            : `${itemData.attrs.src}&r=${randomString(8)}`,
          (darthNode) => {
            // console.log(this.$stage)
            // console.log(darthNode)
            const scaleData = this.countImgPosition(darthNode, itemData.attrs)
            const newAttrs = Object.assign({}, itemData.attrs, scaleData)
            darthNode.setAttrs(newAttrs)
            this.$layer.add(darthNode)

            if (newAttrs.draggable) {
              // add cursor styling
              darthNode.on('mouseover', function () {
                document.body.style.cursor = 'pointer'
              })
              darthNode.on('mouseout', function () {
                document.body.style.cursor = 'default'
              })
            }

            //置底
            if (itemData.attrs.lowestLevel) {
              darthNode.moveToBottom()
              this.$layer.draw()
            }

            return resolve(true)
          }
        )
      } catch (error) {
        return reject(error)
      }
    })
  }

  /**
   * @description: 计算得到图片的居中定位及宽高
   * @param {Dom} img 图片dom
   * @param {Object} attrs //图片的配置数据
   * @Date: 2021-08-19 16:02:15
   * @Author: mulingyuer
   */
  countImgPosition(img, attrs) {
    const space = this.options.custom ? this.$rulerSize : 70
    const stageW = this.$stage.width() //容器真实宽度
    const stageH = this.$stage.height() //容器真实高度
    const wrapW = stageW - space //图片显示最大宽
    const wrapH = stageH - space //图片显示的最大高
    const { myWidth, myHeight } = attrs

    //如果已经算过了，就放弃计算
    if (myWidth && myHeight && myWidth <= wrapW && myHeight <= wrapH) {
      return {}
    }

    const imgW = img.width()
    const imgH = img.height()

    if (imgW <= wrapW && imgH <= wrapH) {
      //图片小于或者等于容器大小
      console.log('图片小于或者等于容器大小')
      return {
        x: this.options.custom ? this.$rulerSize : (stageW - imgW) / 2,
        y: this.options.custom ? this.$rulerSize : (stageH - imgH) / 2,
        width: imgW,
        height: imgH,
        myWidth: imgW,
        myHeight: imgH,
      }
    } else {
      //图片超出容器大小
      const sacleData = {}
      if (imgW > wrapW) {
        console.log('图片超出容器大小,宽度超出')
        sacleData.w = wrapW
        sacleData.h = (wrapW / imgW) * imgH
        console.log(sacleData)
      } else {
        console.log('图片超出容器大小,宽度没超出')
        sacleData.h = wrapH
        sacleData.w = (wrapH / imgH) * imgW
      }

      //缩放后还是大于容器（第一次只是算了单边的等比缩放）
      if (sacleData.w > wrapW) {
        sacleData.h = (wrapW / sacleData.w) * sacleData.h
        sacleData.w = wrapW

        console.log('######################')
      } else if (sacleData.h > wrapH) {
        sacleData.w = (wrapH / sacleData.h) * sacleData.w
        sacleData.h = wrapH
        console.log('@@@@@@@@@@@@@@@@@@@@@@')
      }

      console.log('不知所云')
      return {
        x: this.options.custom ? this.$rulerSize : (stageW - sacleData.w) / 2,
        y: this.options.custom ? this.$rulerSize : (stageH - sacleData.h) / 2,
        // x: 0,
        // y: 0,
        width: sacleData.w,
        height: sacleData.h,
        myWidth: sacleData.w,
        myHeight: sacleData.h,
      }
    }
  }

  /**
   * @description: 添加新图形
   * @param {Object} itemData {className,attrs}
   * @param {Function} callback 添加成功后的回调
   * @Date: 2021-08-19 15:59:28
   * @Author: mulingyuer
   */
  push(itemData, callback) {
    this.drawData(itemData)
      .then(() => {
        if (typeof callback === 'function') callback()
      })
      .catch((error) => {
        throw error
      })
  }

  /**
   * @description: 移除图形
   * @param {String} id 图形id
   * @Date: 2021-08-19 15:59:02
   * @Author: mulingyuer
   */
  remove(id) {
    this.$layer.find(`#${id}`).forEach((shap) => {
      //判断被删除的图形是否选中变形了
      if (this.$transformer && this.$transformer === shap) {
        this.removeTransformer()
      }
      shap.destroy()
    })
  }

  /**
   * @description: 添加标尺
   * @Date: 2021-08-19 15:58:53
   * @Author: mulingyuer
   */
  addRuler() {
    this.$rulerSize = this.options.ruler?.size || 25
    const options = {
      width: this.$stage.width(), //横向宽度
      height: this.$stage.height(), //竖向高度
      wrapSize: this.$rulerSize, //刻度容器大小
      wrapBg: '#FFFBE5', // 尺子的颜色
      lineH: 5, //刻度高度
      lineW: 1, //刻度宽度
      space: this.options.ruler?.space || 5, //刻度间距
      scale: this.options.ruler?.scale || 1, // 刻度是否为真实刻度，大于1表示放大了真实的刻度
      lineColor: '#5C3C00', //刻度颜色
      textColor: '#222222', //字体颜色
      textSize: 12, //字体大小
    }

    // 横向刻度
    const horizontalGroup = new Group({ id: 'horizontalGroup' })
    this.$layer.add(horizontalGroup)
    const horizontalLines = []

    const rulerSize = this.options.custom ? this.$rulerSize : 0

    for (let i = 0; i < options.width + rulerSize; i += options.space) {
      let points = [
        rulerSize + i,
        options.wrapSize,
        rulerSize + i,
        options.wrapSize - options.lineH,
      ] //普通刻度
      if ((i / options.space) % 10 == 0) {
        //满10刻度
        points = [
          rulerSize + i,
          options.wrapSize,
          rulerSize + i,
          options.wrapSize - options.lineH * 2,
        ]
        //刻度文字
        const text = new Text({
          x: rulerSize + i + options.space,
          y: options.wrapSize - options.lineH - options.textSize,
          text: i / (this.options.ruler?.scale || 1),
          fontSize: options.textSize,
          fill: options.textColor,
        })
        horizontalLines.push(text)
      } else if ((i / options.space) % 5 == 0) {
        //满5刻度
        points = [
          rulerSize + i,
          options.wrapSize,
          rulerSize + i,
          options.wrapSize - (options.lineH + 3),
        ]
      }
      horizontalLines.push(
        new Line({
          points,
          stroke: options.lineColor,
          strokeWidth: options.lineW,
        })
      )
    }
    //横向背景色
    const horizontalBg = new Rect({
      x: rulerSize + 0,
      y: 0,
      width: options.width,
      height: options.wrapSize,
      fill: options.wrapBg,
      lowestLevel: true, // 为了误点击的时候刻度和配套文字不消失
    })
    horizontalGroup.add(horizontalBg, ...horizontalLines)

    //竖向刻度
    const landscapeGroup = new Group({ id: 'landscapeGroup' })
    this.$layer.add(landscapeGroup)
    const landscapeLines = []
    for (let i = 0; i < options.height + rulerSize; i += options.space) {
      let points = [
        options.wrapSize,
        rulerSize + i,
        options.wrapSize - options.lineH,
        rulerSize + i,
      ] //普通刻度
      if ((i / options.space) % 10 == 0) {
        //满10刻度
        points = [
          options.wrapSize,
          rulerSize + i,
          options.wrapSize - options.lineH * 2,
          rulerSize + i,
        ]
        //刻度文字
        const text = new Text({
          x: options.wrapSize - options.lineH - options.textSize / 2,
          y: rulerSize + i + options.space,
          text: i / (this.options.ruler?.scale || 1),
          fontSize: options.textSize,
          fill: options.textColor,
          rotation: 90,
        })
        landscapeLines.push(text)
      } else if ((i / options.space) % 5 == 0) {
        //满5刻度
        points = [
          options.wrapSize,
          rulerSize + i,
          options.wrapSize - (options.lineH + 3),
          rulerSize + i,
        ]
      }
      landscapeLines.push(
        new Line({
          points,
          stroke: options.lineColor,
          strokeWidth: options.lineW,
        })
      )
    }
    //横向刻度背景色
    const landscapeBg = new Rect({
      x: 0,
      y: rulerSize + 0,
      width: options.wrapSize,
      height: options.height,
      fill: options.wrapBg,
      lowestLevel: true, // 为了误点击的时候刻度和配套文字不消失
    })
    landscapeGroup.add(landscapeBg, ...landscapeLines)
  }

  /**
   * @description: 显示标尺
   * @param {*}
   * @Date: 2021-08-19 15:58:47
   * @Author: mulingyuer
   */
  showRuler() {
    const idArr = ['horizontalGroup', 'landscapeGroup']
    idArr.forEach((id) => {
      this.$layer.find(`#${id}`).forEach((ruler) => ruler.show())
    })
  }

  /**
   * @description: 隐藏标尺
   * @param {*}
   * @Date: 2021-08-19 15:58:40
   * @Author: mulingyuer
   */
  hideRuler() {
    const idArr = ['horizontalGroup', 'landscapeGroup']
    idArr.forEach((id) => {
      this.$layer.find(`#${id}`).forEach((ruler) => ruler.hide())
    })
  }

  /**
   * @description: 删除标尺
   * @param {*}
   * @Date: 2021-08-19 15:58:35
   * @Author: mulingyuer
   */
  removeRuler() {
    const idArr = ['horizontalGroup', 'landscapeGroup']
    idArr.forEach((id) => {
      this.$layer.find(`#${id}`).forEach((ruler) => ruler.remove())
    })
  }

  /**
   * @description: 地基点击事件
   * @param {Object} event konva事件event对象
   * @Date: 2021-08-19 15:58:17
   * @Author: mulingyuer
   */
  onStageClick(event) {
    if (event.target === this.$stage || event.target.attrs.background) {
      // console.log('点击舞台或背景？')
      this.removeTransformer()
      this.options.backgroundClick()
      return
    } else {
      //非地基和背景图，点击后提升层级
      if (!event.target.attrs.noZIndex && !event.target.attrs.lowestLevel) {
        event.target.moveToTop() //提高层级
        // console.log('提高层级；', event.target) // 如果点击的是尺子，就会被挡住哦
      }
      //触发图形click事件
      this.options.shapeClick(event.target)
    }

    if (!event.target.attrs.transformer) return //不需要变形的图形跳过

    //添加变形事件
    this.addTransformer(event)
  }

  /**
   * @description: 添加Transformer
   * @param {Object} event konva事件event对象
   * @Date: 2021-08-19 15:57:05
   * @Author: mulingyuer
   */
  addTransformer(event) {
    let that = this

    this.removeTransformer() //删除旧事件
    const target = event.target //触发的图形目标
    this.$transformer = target //记住目标
    const tr = new Transformer(
      that.options.custom
        ? {
            keepRatio: true,
            // centeredScaling: true,
            enabledAnchors: [
              'top-left',
              'top-right',
              'bottom-left',
              'bottom-right',
            ],
          }
        : {}
    )

    this.$layer.add(tr)
    tr.nodes([target])
    this.$layer.draw()

    // 监听变形
    tr.on('transform', function () {
      // console.log(target.width() * target.scaleX()) // 界面显示的大小（可以看刻度）

      if (!that.options.custom) return // 怕影响了旧的逻辑，这里只给新的用，2023年10月10日16:16:52

      const { x, y } = target.attrs

      const width = target.width() * target.scaleX()
      const height = target.height() * target.scaleY()

      const stageWidth = that.$stage.width()

      if (width > stageWidth - x) {
        tr.stopTransform() // 禁止变换

        // reset visible width to 200
        // so future transform is possible
        var scaleX = (stageWidth - x) / target.width()
        target.scaleX(scaleX)
      } else {
        target.setAttrs({
          myWidth: width,
          MyHeight: height,
          centerX: x + width / 2,
          centerY: y + height / 2,
        })
      }

      console.log('after setting attrs', target.attrs)
    })

    //添加键盘监听事件
    this.onKeyboardArrowEvent()
  }

  /**
   * @description: 移除Transformer
   * @param {*}
   * @Date: 2021-08-19 15:56:59
   * @Author: mulingyuer
   */
  removeTransformer() {
    this.offKeyboardArrowEvent() //关闭监听键盘
    this.$stage.find('Transformer').forEach((tr) => tr.destroy()) //移除事件
    this.$transformer = null //移除目标
    this.$layer.draw() //重绘
  }

  /**
   * @description: 监听拖拽事件
   * @param {*}
   * @Date: 2021-08-19 15:56:47
   * @Author: mulingyuer
   */
  onWatchDraggable() {
    //拖拽开始
    this.$layer.on('dragstart', (event) => {
      const target = event.target
      this.addAuxiliaryLine(target)
    })
    //拖拽中
    this.$layer.on('dragmove', (event) => {
      const target = event.target
      this.addAuxiliaryLine(target)
    })
    //拖拽结束
    this.$layer.on('dragend', () => {
      this.removeAuxiliaryLine()
    })
  }

  /**
   * @description: 添加辅助线
   * @param {Shape} target 图形对象
   * @Date: 2021-08-19 15:55:54
   * @Author: mulingyuer
   */
  addAuxiliaryLine(target) {
    const options = {
      rulerSize: this.$rulerSize,
      x: target.x(),
      y: target.y(),
      w: target.width(),
      h: target.height(),
      wrapW: this.$stage.width(),
      wrapH: this.$stage.height(),
      lineColor: '#3EB5FB',
      lineWidth: 1,
      dash: [5, 5],
    }

    //基本线条配置
    const baseLineConfig = {
      name: 'auxiliaryLine',
      stroke: options.lineColor,
      strokeWidth: options.lineWidth,
      dash: options.dash,
    }

    //计算每条线的坐标参数
    const x1X = options.x
    const x2X = options.x + options.w
    const y1Y = options.y
    const y2Y = options.y + options.h
    const coordinate = {
      x1: [x1X, options.rulerSize, x1X, options.wrapH],
      x2: [x2X, options.rulerSize, x2X, options.wrapH],
      y1: [options.rulerSize, y1Y, options.wrapW, y1Y],
      y2: [options.rulerSize, y2Y, options.wrapW, y2Y],
    }

    if (this.$layer.find('#auxiliaryLine').length) {
      const group = this.$layer.findOne('#auxiliaryLine')
      //更新坐标
      Object.keys(coordinate).forEach((key) => {
        group.findOne(`#${key}`).points(coordinate[key])
      })
      //组show
      group.show()
    } else {
      const group = new Group({ id: 'auxiliaryLine' })
      Object.keys(coordinate).forEach((key) => {
        const config = Object.assign({ name: key, id: key }, baseLineConfig, {
          points: coordinate[key],
        })
        const item = new Line(config)
        group.add(item)
      })
      this.$layer.add(group)
    }
  }

  /**
   * @description: 隐藏辅助线
   * @param {*}
   * @Date: 2021-08-19 15:55:19
   * @Author: mulingyuer
   */
  hideAuxiliaryLine() {
    const group = this.$layer.find('#auxiliaryLine')
    if (group.length) {
      group.forEach((item) => item.hide())
    }
  }

  /**
   * @description: 删除辅助线
   * @param {*}
   * @Date: 2021-08-19 15:54:45
   * @Author: mulingyuer
   */
  removeAuxiliaryLine() {
    const group = this.$layer.find('#auxiliaryLine')
    if (group.length) {
      group.forEach((item) => item.remove())
    }
  }

  /**
   * @description: 监听键盘事件
   * @param {*}
   * @Date: 2021-08-19 15:54:29
   * @Author: mulingyuer
   */
  onKeyboardArrowEvent() {
    document.addEventListener('keydown', this.onArrowEvent)
    document.addEventListener('keyup', this.onArrowEndEvent)
  }

  /**
   * @description:  移除监听键盘事件
   * @param {*}
   * @Date: 2021-08-19 15:54:19
   * @Author: mulingyuer
   */
  offKeyboardArrowEvent() {
    document.removeEventListener('keydown', this.onArrowEvent)
    document.removeEventListener('keyup', this.onArrowEndEvent)
  }

  /**
   * @description: 方向键移动事件
   * @param {*} event
   * @Date: 2021-08-19 15:54:10
   * @Author: mulingyuer
   */
  onArrowEvent(event) {
    //如果是input触发就跳过
    if (hasOwnProperty(event, 'myKonvaisInput')) return

    const key = event.key
    switch (key) {
      case 'ArrowRight':
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowUp':
        event.preventDefault()
        break
      default:
        return //其他按键直接跳过
    }

    const target = this.$transformer
    const { x, y } = target.getAttrs() //坐标
    const width = target.width() * target.scaleX() //目标宽度
    const height = target.height() * target.scaleY() //目标高度
    const maxWidth = this.$stage.width() - width //最大移动宽度
    const maxHeight = this.$stage.height() - height //最大移动高度

    switch (key) {
      case 'ArrowRight': //往右
        const rightX = x + 1 <= maxWidth ? x + 1 : maxWidth
        target.setAttr('x', rightX)
        break
      case 'ArrowDown': //往下
        const downY = y + 1 <= maxHeight ? y + 1 : maxHeight
        target.setAttr('y', downY)
        break
      case 'ArrowLeft': //往左
        const leftX = x - 1 >= 0 ? x - 1 : 0
        target.setAttr('x', leftX)
        break
      case 'ArrowUp': //往上
        const upY = y - 1 >= 0 ? y - 1 : 0
        target.setAttr('y', upY)
        break
    }
    //显示辅助线
    this.addAuxiliaryLine(target)
  }

  /**
   * @description: 方向键移动结束事件
   * @param {Object} event
   * @Date: 2021-08-19 15:53:50
   * @Author: mulingyuer
   */
  onArrowEndEvent(event) {
    event.preventDefault()

    const key = event.key
    switch (key) {
      case 'ArrowRight':
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowUp':
        //隐藏辅助线
        this.hideAuxiliaryLine()
        break
    }
  }

  /**
   * @description: 生成图片(base64)
   * @param {Number} ratio 生成图片的放大倍率
   * @Date: 2021-08-19 15:40:20
   * @Author: mulingyuer
   */
  toDataURL(ratio = 1) {
    this.removeTransformer() //先移除变形
    this.hideRuler() //隐藏标尺
    this.hideAuxiliaryLine() //隐藏辅助线
    return this.$stage.toDataURL({ pixelRatio: ratio }) // 目前没有去除尺子的占位，图片内容会偏右哦
  }

  /**
   * @description: 下载文件方法（仅base64）
   * @param {base64} url 下载地址
   * @param {String} fileName 文件名
   * @Date: 2021-08-19 15:39:03
   * @Author: mulingyuer
   */
  downloadURI(url, fileName = 'stage.png') {
    const link = document.createElement('a')
    link.download = fileName
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    link.remove()
  }

  /**
   * @description: 生成并下载图片
   * @param {String} fileName 文件名
   * @param {Number} ratio 生成图片的放大倍率
   * @Date: 2021-08-19 15:37:29
   * @Author: mulingyuer
   */
  exportImg({ fileName, ratio }) {
    this.downloadURI(this.toDataURL(ratio), fileName)
  }

  /**
   * @description: 更新整个图形对象
   * @param {Array} arr [{className,attr},...]
   * @Date: 2021-08-19 15:34:46
   * @Author: mulingyuer
   */
  update(arr = []) {
    arr.forEach((itemData) => {
      const shapesArr = this.$layer.find(`#${itemData.attrs.id}`)
      shapesArr.forEach((shap) => shap.destroy()) //销毁旧实例
      this.drawData(itemData) //重建
    })
  }

  /**
   * @deprecated
   * @description: 更新图形单个attr属性
   * @param {Object} data {id,attr,value}
   * @Date: 2021-08-19 15:33:22
   * @Author: mulingyuer
   */
  updataAttr(data = {}) {
    if (!data.id) throw new Error('更新的组件id不存在')
    const shapesArr = this.$layer.find(`#${data.id}`)
    //通用设置属性
    shapesArr.forEach((shape) => {
      shape.setAttr(data.attr, data.value)
      this.$layer.draw()
    })
  }

  /**
   * @description: 更新单个图形的多个attr属性
   * @param {Object} data {id,attrs}
   * @Date: 2021-08-19 15:32:24
   * @Author: mulingyuer
   */
  updateAttrs(data = {}) {
    if (!data.id) throw new Error('更新的组件id【'+data.id+'】不存在')

    const shapesArr = this.$layer.find(`#${data.id}`)
    const { attrs } = data
    if (attrs) {
      shapesArr.forEach((shape) => {
        shape.setAttrs(attrs)
        this.$layer.draw()
      })
    }
  }

  /**
   * 获取实时的宽高（myWidth, myHeight, 但是没有修改底层数据，只是计算了一下）或其他所有属性
   * @param id
   */
  getMyAttrs(id) {
    if (!id) throw new Error('目标组件id不存在')
    const shapesArr = this.$layer.find(`#${id}`)
    shapesArr.forEach((shape) => {
      const {id, align, fontStyle} = shape.attrs
      console.log(id, align, fontStyle, '实时获取的形状属性')
    })
    const target = shapesArr[0]
    const width = target.width() * target.scaleX()
    const height = target.height() * target.scaleY()

    // console.log(width, height, "it's real")

    return { ...shapesArr[0].attrs, myWidth: width, myHeight: height }
  }

  /**
   * @description: 指定图形分类名组件的显隐
   * @param {String} name 图形分类名
   * @param {Boolean} visible 显示&隐藏
   * @Date: 2021-08-19 15:30:43
   * @Author: mulingyuer
   */
  classifyVisible(name, visible) {
    //获取图形
    const classifyArr = this.$layer.find((shape) => {
      return shape.getAttr('classify') === name
    })
    //处理
    if (visible) {
      classifyArr.forEach((shape) => shape.show())
    } else {
      classifyArr.forEach((shape) => shape.hide())
    }
  }

  /**
   * @description: 获取classify组件的中心点
   * @param {String} name 图形分类名
   * @Date: 2021-08-19 15:27:18
   * @Author: mulingyuer
   */
  setClassifyCenterPoint(name) {
    const classifyArr = this.$layer.find((shape) => {
      return shape.getAttr('classify') === name
    })

    classifyArr.forEach((shape) => {
      const { x, y } = shape.attrs
      const width = shape.width() * shape.scaleX()
      const height = shape.height() * shape.scaleY()
      shape.setAttrs({
        myWidth: width,
        MyHeight: height, // 旧版本中在用
        myHeight: height, // 新属性，和 myWidth 配套使用
        centerX: x + width / 2,
        centerY: y + height / 2,
      })
    })
  }

  /**
   * @description: 导出json配置
   * @param {String} classifyName 图形分类名
   * @param {Boolean} isGetCenterPoint 是否需要导出指定图形分类名的中心点参数
   * @Date: 2021-08-19 15:19:45
   * @Author: mulingyuer
   */
  exportConfig({ classifyName, isGetCenterPoint = false } = {}) {
    this.removeRuler() //删除刻度尺配置
    this.removeAuxiliaryLine() //删除辅助线
    //判断是否需要获取classifyName的中心点参数
    if (isGetCenterPoint && classifyName)
      this.setClassifyCenterPoint(classifyName)

    // const stageData = JSON.parse(this.$stage.toJSON())
    // console.log(JSON.parse(this.$stage.toJSON()), '$$$$$$$$')

    // stageData.children[0].children.forEach(chd => {
    //   const realAttrs = this.getMyAttrs(chd.attrs.id)
    //   console.log(realAttrs, 'real')
    // })

    return {
      stage: this.$stage.toJSON(), // toJSON() 后我看到有些属性会丢失诶
      layer: this.$layer.toJSON(),
    }
  }

  getStage() {
    return JSON.parse(this.$stage.getStage().toJSON())
  }

  getLayer() {
    return JSON.parse(this.$layer.getLayer().toJSON())
  }

  /**
   * @description: 读取json配置时特别操作：图片
   * @param {*}
   * @Date: 2021-08-19 16:07:31
   * @Author: mulingyuer
   */
  readJSONSpecial() {
    return new Promise(async (resolve, reject) => {
      try {
        const imgShapesArr = this.$layer.find('Image')
        for (let imgShapes of imgShapesArr) {
          await this.drawImage({ attrs: imgShapes.attrs }, imgShapes) //重建
        }
        return resolve(true)
      } catch (error) {
        return reject(error)
      }
    })
  }

  /**
   * @description: 销毁
   * @param {*}
   * @Date: 2021-08-19 16:07:52
   * @Author: mulingyuer
   */
  destroy() {
    this.offKeyboardArrowEvent() //关闭键盘事件
    this.$stage.destroy()
    //手动回收添加的属性
    for (let key in this) {
      this[key] = null
    }
  }

  //工具
  utils = {
    //判断类型
    getType(value) {
      let type = typeof value
      if (type !== 'object') {
        return type
      }
      return Object.prototype.toString.call(value).slice(8, -1).toLowerCase()
    },
    //修改函数this指向
    bind(vm, fn) {
      return fn.bind(vm)
    },
  }

  /**
   * @description:  静态创建方法
   * @param {array} args 参数
   * @Date: 2021-08-19 16:08:03
   * @Author: mulingyuer
   */
  static create(...args) {
    return new this(...args)
  }
}

export default MyKonva
