<!--
 * @Author: mulingyuer
 * @Date: 2021-06-23 17:26:43
 * @LastEditTime: 2021-08-19 14:59:30
 * @LastEditors: mulingyuer
 * @Description: my-konva
 * @FilePath: \super-admin-vue\src\base\components\MyKonva\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    :title="custom ? '标签设计' : '证书编辑'"
    :visible.sync="show"
    :width="dialogWidth || '1320px'"
    class="dialog-vertical my-konva"
    @open="onOpen"
    @close="onClose"
  >
    <div class="content" :style="{ height: custom ? '775px' : '' }">
      <div class="left">
        <div v-if="showUpload" class="left-item img-upload">
          <h2 class="title">{{ custom ? '标签印刷底图' : '证书上传' }}</h2>
          <p class="subtitle">
            为了保证质量，建议尺寸1240*1754px或1754px*1240px，大小不超过2M
          </p>
          <div class="upload-wrap" @click="openLeftMedia">
            <div v-if="!custImgSrc" class="add">
              <i class="el-icon-plus icon"></i>
            </div>
            <div v-else class="img-wrap">
              <img class="view" :src="fixImageUrl(custImgSrc)" />
              <div class="view-info" @click.stop="onLeftDelete">删除</div>
            </div>
          </div>
        </div>

        <template v-if="custom">
          <!--    提示：标签宽度范围：50 ~ 75<br/>-->
          <div class="flex-col left-item" style="margin-bottom: 20px">
            <!--
                 :max="75 * 4 + 25"
                -->
            <h2 class="title">标签宽度</h2>
            <el-slider v-model="userWidth" :min="30" :max="75"> </el-slider>
            <el-input-number
              controls-position="right"
              v-model="userWidth"
              size="medium"
              :max="75"
            ></el-input-number>
          </div>
          <div class="left-item flex-col">
            <h2 class="title">标签高度</h2>
            <el-slider v-model="userHeight" :min="30" :max="75"> </el-slider>
            <el-input-number
              controls-position="right"
              v-model="userHeight"
              size="medium"
              style="width: 100%"
            ></el-input-number>
          </div>
          <div class="left-item flex-col">
            <el-button @click.stop.prevent="resizeKonva" type="primary"
              >变更尺寸</el-button
            >
          </div>
        </template>

        <div v-if="templateData.length > 0" class="left-item template-list">
          <h2 class="title">模板列表</h2>
          <div class="list-wrap">
            <el-scrollbar class="list-scroll">
              <div
                class="list-item"
                :class="{ active: activeTemplateId === item.id }"
                v-for="(item, index) in templateData"
                :key="`${item.image}${index}`"
                @click="onLeftActiveTemplate(item)"
              >
                <img :src="fixImageUrl(item.image)" />
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
      <div class="right" v-loading="loading">
        <div class="right-tool">
          <el-tabs
            v-if="resetBoolean"
            class="right-tabs"
            v-model="activeName"
            @tab-click="onRightTabsHandleClick"
          >
            <el-tab-pane label="变量词" name="fixedVariable" lazy>
              <div class="right-tabs-content">
                <el-scrollbar wrap-class="right-scroll">
                  <div class="scroll-content" style="margin-top: 20px">
                    <RightFormItem
                      v-for="item in fixedVariableData"
                      :key="item.attrs.id"
                      :id="`myKonva-${item.attrs.id}`"
                      :ref="`myKonva-${item.attrs.id}`"
                      :showValueInput="isAdmin"
                      :data="item"
                      :item-data="item"
                      :custom="custom"
                      :onUpdate="onRightUpdate"
                      :active="item.attrs.id === active_element_id"
                      @choose="onChooseElement(item, $event)"
                    />
                  </div>
                </el-scrollbar>
              </div>
            </el-tab-pane>
            <el-tab-pane label="自定义文本" name="customVariable" lazy>
              <div class="right-tabs-content">
                <div
                  v-if="activeName === 'customVariable'"
                  class="add-custom-variable"
                >
                  <el-button
                    size="medium"
                    type="text"
                    icon="el-icon-plus"
                    @click="onRightAddCustom"
                  >
                    新增自定义文本
                  </el-button>
                </div>
                <el-scrollbar wrap-class="right-scroll">
                  <div class="scroll-content">
                    <RightFormItem
                      v-for="item in customVariableData"
                      :key="item.attrs.id"
                      :id="`myKonva-${item.attrs.id}`"
                      :ref="`myKonva-${item.attrs.id}`"
                      :data="item"
                      :onUpdate="onRightUpdate"
                      :onDelete="onRightDelete"
                      :showDelete="true"
                    />
                  </div>
                </el-scrollbar>
              </div>
            </el-tab-pane>
            <el-tab-pane
              v-if="!custom"
              label="自定义图片"
              name="customImage"
              lazy
            >
              <div class="right-tabs-content">
                <div
                  v-if="activeName === 'customImage'"
                  class="add-custom-variable"
                >
                  <el-button
                    size="medium"
                    type="text"
                    icon="el-icon-plus"
                    @click="onRightAddImage"
                  >
                    新增自定义图片
                  </el-button>
                </div>
                <el-scrollbar wrap-class="right-scroll">
                  <div class="scroll-content">
                    <right-image-item
                      v-for="item in customImageData"
                      :key="item.attrs.id"
                      :id="`myKonva-${item.attrs.id}`"
                      :ref="`myKonva-${item.attrs.id}`"
                      :data="item"
                      :onUpdate="onRightUpdate"
                      :onDelete="onRightDelete"
                      :showDelete="true"
                    />
                  </div>
                </el-scrollbar>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div
          class="right-content flex-col"
          :class="{ custom }"
          :style="
            custom
              ? {
                  '--data-w': width + 'px',
                  '--data-h': height + 'px',
                  '--ruler-s': '25px',
                  // width: custom ? '750px' : '',
                }
              : {}
          "
          :data-w="width + 'px'"
          :data-h="height + 'px'"
        >
          <!-- 类似 PowerPoint 元素对齐操作 -->
          <div
            v-if="custom"
            class="flex"
            style="
              padding: 20px;
              background-color: white;
              border-bottom: 1px solid gray;
              margin-bottom: 0px;
              z-index: 2;
            "
          >
            <el-button-group>
              <el-button
                size="mini"
                @click="onClickAlign(align.value)"
                v-for="align in alignArr"
                :key="align.value"
                type=""
                :label="align.value"
                >{{ align.label }}</el-button
              >
            </el-button-group>
            <el-button-group style="margin-left: 20px">
              <el-button
                size="mini"
                @click="onClickAlign(align.value, 'vertical')"
                v-for="align in verticalAlignArr"
                :key="align.value"
                type=""
                :label="align.value"
                >{{ align.label }}</el-button
              >
            </el-button-group>
          </div>
          <!--
           style="transform: scale(1.5);transform-origin: top left;"
          -->
          <div class="content" ref="myKonvaContent"></div>
        </div>
      </div>
    </div>
    <template #footer>
      <el-button size="medium" @click="show = false">取消</el-button>
      <el-button
        size="medium"
        type="primary"
        :loading="saveLoading"
        @click="onSave"
        >保存</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import MyKonva from './utils/my_konva'
import { uuid } from '@/base/utils/tool'
import { dataURLtoFile, hasOwnProperty } from './utils/tool'
import RightFormItem from './components/right/RightFormItem'
import { fixedVariable, customVariable } from './data/content'
import { defaultCustomData, defaultImageData } from './data/content'
import RightImageItem from '@/base/components/MyKonva/components/right/RightImageItem'
export default {
  name: 'base-konva',
  model: {
    prop: 'open',
    event: 'handleOpen',
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //模板数据
    templateData: {
      type: Array,
      required: true,
    },
    //配置数据
    configData: {
      type: Object,
      required: true,
    },
    //当前配置的模板预览图 可选sync
    preview: {
      type: String,
    },
    //手机端的模板底图 可选sync
    mobilePreview: {
      type: String,
    },
    //上传文件api
    uploadApi: {
      type: Function,
      required: true,
    },
    //是否新增模式
    isNewAdd: {
      type: Boolean,
      default: true,
    },
    //是否显示上传组件
    showUpload: {
      type: Boolean,
      default: true,
    },
    //管理页模式
    isAdmin: {
      type: Boolean,
      default: false,
    },
    // 自定义（活动标签）
    custom: Boolean,
    // 根据业务开发者自定义变量词，不是用户自定义
    customFixedVariable: Array,
    dialogWidth: String,
    canvasWidth: Number,
    canvasHeight: Number,
  },
  data() {
    return {
      loading: false, //加载中
      custImgSrc: '', //自定义上传的图片
      activeTemplateId: '', //选择的模板id
      $myKonva: null, //canvas实例
      activeName: 'fixedVariable', //右侧tabs选中的name
      saveLoading: false, //保存中
      fixedVariableData: [], //变量词数据
      customVariableData: [], //自定义变量数据
      customImageData: [], // 自定义图片数据
      resetBoolean: false, //重置开关
      addCustomTop: 1, //添加自定义文本时y轴距离倍数

      // 尺寸
      // width: 750,
      // height: 750,
      width: 760,
      height: 800,
      // width: 600, // 将贴纸的真实尺寸放大10倍
      // height: 400, // 将贴纸的真实尺寸放大10倍

      // 用户输入的尺寸，是上面尺寸的十分之一
      userWidth: 75,
      userHeight: 75,

      elementAlign: 'left',
      alignArr: [
        {
          value: 'left',
          label: '左对齐',
        },
        {
          value: 'center',
          label: '居中',
        },
        {
          value: 'right',
          label: '右对齐',
        },
      ],
      elementVerticalAlign: 'top',
      verticalAlignArr: [
        {
          value: 'top',
          label: '顶部对齐',
        },
        {
          value: 'middle',
          label: '垂直居中',
        },
        {
          value: 'bottom',
          label: '底部对齐',
        },
      ],

      active_element_id: '',
    }
  },
  computed: {
    show: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('handleOpen', val)
      },
    },
    //是否绑定了preview
    isPreview({ preview }) {
      return typeof preview === 'string'
    },
    //是否绑定了mobilePreview
    isMobilePreview({ mobilePreview }) {
      return typeof mobilePreview === 'string' && !this.custom
    },
  },
  // watch: {
  //   userWidth(val) {
  //     this.width = val * 10
  //   },
  //   userHeight(val) {
  //     this.height = val * 10
  //     // console.log(this.height, 'height updated. ', val)
  //   }
  // },
  //接收注入-打开媒体库弹窗
  inject: ['openMedia'],
  methods: {
    /**
     * 模拟 PowerPoint 元素对齐方式
     * @param align
     * @param direction
     */
    onClickAlign(align = 'center', direction = 'horizontal') {
      // console.log('click align element: ', align, 'id: ', this.active_element_id)
      // console.log(align, direction, '@@@@@@@')

      if (!this.active_element_id) {
        this.$message.info('请先选定一个元素再操作')
        return
      }
      // TODO：如果元素选中状态已取消呢

      const rulerSize = 25

      const childItem = this.$refs['myKonva-' + this.active_element_id][0]

      if (!childItem) return

      const realtimeAttrs = this.$myKonva.getMyAttrs(this.active_element_id)

      if (direction === 'horizontal') {
        const index = this.fixedVariableData.findIndex(
          (item) => item.attrs.id === realtimeAttrs.id
        )
        console.log('yes horizontal', index)

        this.fixedVariableData[index].attrs.align = align
        // console.log(this.fixedVariableData[index].attrs, 'after setting')
        // this.$set(this.fixedVariableData[index].attrs, 'align', align)
      }

      switch (align) {
        case 'center': {
          // console.log(childItem)
          // console.log(childItem?.form)

          this.$myKonva.updateAttrs({
            id: this.active_element_id,
            attrs: {
              // ...childItem.form,
              // centerX: this.width / 2 + rulerSize,
              x: this.width / 2 + rulerSize - realtimeAttrs.myWidth / 2,
              align: 'center',
            },
          })

          break
        }
        case 'left': {
          this.$myKonva.updateAttrs({
            id: this.active_element_id,
            attrs: {
              x: rulerSize,
              align: 'left',
            },
          })
          break
        }
        case 'right': {
          this.$myKonva.updateAttrs({
            id: this.active_element_id,
            attrs: {
              x: this.width + rulerSize - realtimeAttrs.myWidth,
              align: 'right',
            },
          })
          console.log(this.$myKonva.getMyAttrs(this.active_element_id))

          // TODO: 通知右边改对齐方式
          break
        }
        case 'top': {
          this.$myKonva.updateAttrs({
            id: this.active_element_id,
            attrs: {
              y: rulerSize,
            },
          })
          break
        }
        case 'bottom': {
          this.$myKonva.updateAttrs({
            id: this.active_element_id,
            attrs: {
              y: this.height + rulerSize - realtimeAttrs.myHeight,
            },
          })
          break
        }
        case 'middle': {
          this.$myKonva.updateAttrs({
            id: this.active_element_id,
            attrs: {
              y: this.height / 2 + rulerSize - realtimeAttrs.myHeight / 2,
            },
          })
          break
        }
        default:
      }
    },
    /**
     * 更改尺寸
     */
    resizeKonva() {
      console.log('resize click! ')
      this.width = this.userWidth * 10
      this.height = this.userHeight * 10

      this.$nextTick(() => {
        if (this.isInitEnd()) {
          this.$myKonva.destroy()
          this.$myKonva = null
        }

        this.contentInit()
        console.log('resize...........')
      })
    },
    //打开
    onOpen() {
      if (this.canvasWidth) {
        this.userWidth = this.canvasWidth / 10
        this.width = this.canvasWidth
      }
      if (this.canvasHeight) {
        this.userHeight = this.canvasHeight / 10
        this.height = this.canvasHeight
      }

      this.$nextTick(() => {
        this.resetBoolean = true
        this.initRightData() //初始化右侧数据
        this.contentInit() //初始化内容
      })
    },
    //关闭
    onClose() {
      if (this.isInitEnd()) {
        this.$myKonva.destroy()
        this.$myKonva = null
      }
      Object.assign(this.$data, this.$options.data())

      // if (this.canvasWidth) {
      //   this.userWidth = this.canvasWidth / 10
      //   this.width = this.canvasWidth
      // }
      // if (this.canvasHeight) {
      //   this.userHeight = this.canvasHeight / 10
      //   this.height = this.canvasHeight
      // }
    },
    //左侧打开媒体库弹窗
    openLeftMedia() {
      this.openMedia((url) => {
        if (url) {
          this.custImgSrc = url
          this.onPushBackgroundImage(url)
        }
      })
    },
    //左侧删除图片事件
    onLeftDelete() {
      if (!this.custImgSrc) return
      this.custImgSrc = ''
      this.onRemoveShap('background')
    },
    //左侧选中模板
    onLeftActiveTemplate(data) {
      this.activeTemplateId = data.id
      this.onLeftDelete() //左侧删除图片
      this.resetBoolean = false
      //编辑
      this.initRightData(data.config)
      setTimeout(() => {
        this.resetBoolean = true
      }, 200)
      this.readConfigInit(data.config)
    },
    //内容初始化：1.新增；2.编辑；3.读取配置
    contentInit() {
      // console.log('是编辑还是新增（true：新增）', this.isNewAdd)
      if (this.isNewAdd) {
        //新增
        if (this.templateData.length) {
          //存在模板优先读取第一个模板
          this.onLeftActiveTemplate(this.templateData[0])
        } else {
          this.loading = true
          // console.log(fixedVariable, 'ffffffffix')
          console.log('canvas size: ', this.width, this.height)

          // if (this.canvasWidth) {
          //   this.width = this.canvasWidth
          //   this.height = this.
          //   this.height = this.canvasHeight
          //   this.userWidth = this.canvasWidth ? this.canvasWidth / 10 : this.width / 10
          //   this.userHeight = this.canvasHeight ? this.canvasHeight / 10 : this.height / 10
          // }

          const rulerSize = 25

          this.$myKonva = new MyKonva({
            container: this.$refs.myKonvaContent,
            // width: this.canvasWidth || this.width,
            width: this.width + rulerSize,
            // height: this.canvasHeight || this.height,
            height: this.height,
            ruler: {
              size: rulerSize,
              scale: this.custom ? 10 : 1, // 影响的是刻度表示的数值
            },
            onload: () => {
              this.loading = false
            },
            shapeClick: this.onShapeClick,
            backgroundClick: this.onBackgroundClick,
            data: this.custom ? this.customFixedVariable : [...fixedVariable],
            // , ...customVariable
            custom: this.custom,

            // onTransform: function (target) {
            //   console.log(target)
            //   console.log(target.id)
            //   console.log(target.attrs)
            // },
          })
        }
      } else {
        // 编辑
        this.readConfigInit(this.configData)
      }
    },
    /**
     * 初始化
     * @param stageData
     * @returns {any}
     */
    rebuildStageData(stageData) {
      const parsedStage = JSON.parse(stageData)
      const rulerSize = 25

      parsedStage.attrs.width = this.width + rulerSize
      parsedStage.attrs.height = this.height + rulerSize
      // parsedStage.attrs.align = parsedStage.attrs.align || 'center'

      // console.log(this.custImgSrc, 5555555)

      parsedStage.children.forEach((ch, chI) => {
        const bg = ch.children[0]
        // console.log(ch, bg, '~~~~~~~~~~~')
        if (bg.attrs.id === 'background') {
          ch.children[0].attrs.src = this.custImgSrc
        }
        // console.log(bg)
        // if (bg.attrs.name === 'text') {
        //   // ch.children[0].children.forEach((chd, chdI) => {
        //   //   if (chd.attrs.align === undefined) {
        //   //     ch.children[0].children[chdI].attrs.align = 'center'
        //   //   }
        //   // })
        //   parsedStage.children[chI].children[0].attrs.align = ch.children[0].attrs.align || 'center'
        // }

        // ch.children.forEach((chd, chdI) => {
        //   if (chd.className === 'Text') {
        //     // console.log(chd.attrs.align, '&&&')
        //     if (chd.attrs.align === undefined) {
        //       parsedStage.children[chI].children[chdI].attrs.align = 'center'
        //     }
        //     if (chd.attrs.fontStyle === undefined) {
        //       parsedStage.children[chI].children[chdI].attrs.fontStyle = 'bold'
        //     }
        //   }
        // })
      })

      return parsedStage
    },
    // 读取配置文件初始化
    readConfigInit(configData) {
      // console.log(configData, ';ccccccccccccc')

      const rulerSize = 25

      const { stage, layer } = configData
      // console.log(JSON.parse(stage), 'stage')
      // console.log(JSON.parse(layer), 'layer')

      // console.log(this.width, this.height, 'xxxxxxxxxxxxx')
      // console.log(this.userWidth, this.userHeight, 'yyyyyyyyyyyyyyyyyy')

      // const parsedStage = JSON.parse(stage)
      // parsedStage.attrs.width = this.userWidth * 10
      // parsedStage.attrs.height = this.userHeight * 10

      // const parsedLayer = JSON.parse(layer)
      // console.log(parsedLayer, 111111111111111111)
      // if (this.custImgSrc) {
      //   parsedLayer.children.forEach((ch, chI) => {
      //     if (ch.attrs.id === 'background') {
      //       parsedLayer.children[chI].attrs.src = this.custImgSrc
      //     }
      //   })
      // }

      const parsedStage = this.rebuildStageData(stage)

      // console.log(parsedStage, 'pppppppPPPPPPPPPPPPPPP')

      configData.stage = JSON.stringify(parsedStage)

      // configData.layer = JSON.stringify(parsedLayer)

      // const parsedLayer = this.rebuildLayer(layer)
      // configData.layer = JSON.stringify(parsedLayer)

      // console.log(configData, 'ccccccccccccccccc!!!!!!')

      this.loading = true
      this.$myKonva = new MyKonva({
        container: this.$refs.myKonvaContent,
        json: configData,
        // scale: 10, // 实际大小要当前尺寸除以10
        ruler: {
          scale: this.custom ? 10 : 1, // 影响的是刻度表示的数值, 大于1，实际值比刻度原始值小
        },
        onload: () => {
          // 背景替换
          if (this.custImgSrc) this.onPushBackgroundImage(this.custImgSrc)

          this.loading = false
        },
        shapeClick: this.onShapeClick,
        custom: this.custom,

        backgroundClick: this.onBackgroundClick,
      })
    },
    // 检测实例是否初始化完毕
    isInitEnd() {
      if (!this.$myKonva) {
        this.$message.error('模板暂未初始化完毕，请稍后再试！')
        return false
      }
      return true
    },
    //插入背景图
    onPushBackgroundImage(src) {
      if (this.isInitEnd()) {
        this.loading = true
        this.$myKonva.remove('background')
        const data = {
          className: 'image',
          attrs: {
            id: 'background',
            src,
            lowestLevel: true, //最底层
            background: true, //声明底图
            x: 25,
            y: 25, // 尺子尺寸，不知道为啥没保存到这俩属性
          },
        }
        this.$myKonva.push(data, () => {
          this.loading = false
        })
      }
    },
    //删除图形
    onRemoveShap(id) {
      if (this.isInitEnd()) {
        this.$myKonva.remove(id)
      }
    },
    //初始化右侧数据
    initRightData(configData) {
      if (configData || !this.isNewAdd || Object.keys(this.configData).length) {
        const { layer, stage } = configData ?? this.configData

        const parsedStage = this.rebuildStageData(stage)

        // 新版统一用 stage，但是它的层级太恶心了
        const { children: stageData } = this.custom ? parsedStage.children[0] : JSON.parse(layer)

        this.fixedVariableData = stageData.filter(({ attrs }) => {
          return attrs.classify === 'fixedVariable'
        })
        this.customVariableData = stageData.filter(({ attrs }) => {
          return attrs.classify === 'customVariable'
        })
        this.customImageData = stageData.filter(({ attrs }) => {
          return attrs.classify === 'customImage'
        })
      } else {
        //新增
        this.fixedVariableData = this.custom
          ? this.customFixedVariable
          : fixedVariable
      }
    },
    //右侧tabs切换
    onRightTabsHandleClick() {
      // console.log("切换了");
    },
    //右侧数据更新事件
    onRightUpdate(data) {
      if (!this.isInitEnd()) return
      this.$myKonva.updateAttrs(data)
    },
    //新增自定义文本
    onRightAddCustom() {
      if (!this.isInitEnd()) return
      if (this.$addTimer) clearTimeout(this.$addTimer)
      const data = defaultCustomData()
      data.attrs.id = uuid()
      data.attrs.y = this.addCustomTop++ * data.attrs.y
      if (data.attrs.y >= 800) this.addCustomTop = 1
      this.customVariableData.push(data)
      this.$myKonva.push(data)
      this.$addTimer = setTimeout(() => {
        this.addCustomTop = 1
      }, 500)
    },
    onRightAddImage() {
      if (!this.isInitEnd()) return
      this.openMedia((url) => {
        if (url) {
          let data = defaultImageData()
          data.attrs.id = uuid()
          data.attrs.src = url
          this.customImageData.push(data)
          this.$myKonva.push(data)
        }
      })
    },
    //右侧删除事件
    onRightDelete({ attrs }) {
      if (!this.isInitEnd()) return
      const index = this.customVariableData.findIndex(
        (item) => item.attrs.id === attrs.id
      )
      if (index !== -1) this.customVariableData.splice(index, 1) //数据删除

      const index2 = this.customImageData.findIndex(
        (item) => item.attrs.id === attrs.id
      )
      if (index2 !== -1) this.customImageData.splice(index2, 1)

      this.onRemoveShap(attrs.id) // 图形删除
    },
    //生成预览图片
    getMyKonvaPreviewSrc() {
      return new Promise((resolve, reject) => {
        if (!this.isInitEnd()) reject(false) //实例不存在
        const dataURL = this.$myKonva.toDataURL(3) //3倍率
        //转file对象
        const file = dataURLtoFile(dataURL)
        //上传
        this.uploadApi(file)
          .then((res) => {
            const { data } = res
            return resolve(data.url)
          })
          .catch((err) => {
            return reject(err)
          })
      })
    },
    //生成手机端预览底图
    getMyKonvaMobilePreviewSrc() {
      return new Promise(async (resolve, reject) => {
        if (!this.isInitEnd()) reject(false) //实例不存在
        this.$myKonva.classifyVisible('fixedVariable', false)
        this.getMyKonvaPreviewSrc()
          .then((url) => {
            this.$myKonva.classifyVisible('fixedVariable', true)
            return resolve(url)
          })
          .catch((err) => {
            return reject(err)
          })
      })
      // console.log(typeof this.mobilePreview === "string");
    },
    onChooseElement({ attrs }, visible) {
      // console.log(attrs, 'click to focus element?')

      if (visible) {
        this.$myKonva.activateShape(attrs.id)
      } else {
        this.$myKonva.removeTransformer()
      }
    },
    mapLogger(arr) {
      console.log(arr.map(el => {
        return el.attrs.id + ': ' + el.attrs.align + ', ' + el.attrs.fontStyle
      }))
    },
    rebuildLayer(layerData) {
      const parsedLayer = JSON.parse(layerData)
      parsedLayer.children.forEach((ch, chI) => {
        const {attrs} = parsedLayer.children[chI]
        const {id, align ,fontStyle} = attrs
        if (align === undefined) {
          parsedLayer.children[chI].attrs.align = this.$myKonva.getMyAttrs(id).align || 'center'
        }
        if (fontStyle === undefined) {
          parsedLayer.children[chI].attrs.fontStyle = this.$myKonva.getMyAttrs(id).fontStyle || 'bold'
        }
        parsedLayer.children[chI].attrs.fill = '#000'
      })
      return parsedLayer
    },
    rebuildStage(stageData) {
      const parsedStage = JSON.parse(stageData)
      if (parsedStage.children.length === 1) {
        parsedStage.children[0].children.forEach((ch, chI) => {
          const {attrs} = ch
          const {id, align ,fontStyle} = attrs
          if (align === undefined) {
            parsedStage.children[0].children[chI].attrs.align = this.$myKonva.getMyAttrs(id).align || 'center'
          }
          if (fontStyle === undefined) {
            parsedStage.children[0].children[chI].attrs.fontStyle = this.$myKonva.getMyAttrs(id).fontStyle || 'bold'
          }
          parsedStage.children[0].children[chI].attrs.fill = '#000'
        })
      }
      return parsedStage
    },
    //保存
    async onSave() {
      try {
        this.saveLoading = true

        this.$myKonva.removeTransformer() // 移除变形器

        // 手动改了尺寸
        this.$emit('resize', { w: this.userWidth, h: this.userHeight })

        // 导出配置
        const jsonData = this.$myKonva.exportConfig({
          classifyName: 'fixedVariable', //获取中心点的classifyname
          isGetCenterPoint: true, //是否获取中心点位置参数
        })

        if (jsonData) {
          console.log('当前保存的标签设计配置数据：')
          // console.log(JSON.parse(jsonData.layer))
          // this.mapLogger(JSON.parse(jsonData.layer).children)
          // console.log(this.$myKonva.getLayer())
          // this.mapLogger(this.$myKonva.getLayer().children)
          // console.log(JSON.parse(jsonData.stage))
          // this.mapLogger(JSON.parse(jsonData.stage).children[0].children)
          // // this.mapLogger(this.$myKonva.getStage().children[0].children)
          // console.log(JSON.parse(jsonData.stage).attrs)

          console.log(this.rebuildLayer(jsonData.layer), 'rebuild layer')
          jsonData.layer = JSON.stringify(this.rebuildLayer(jsonData.layer))

          console.log(this.rebuildStage(jsonData.stage), 'rebuild stage')
          jsonData.stage = JSON.stringify(this.rebuildStage(jsonData.stage))
        }

        // stage 的 attrs 有记录宽高

        this.$emit('update:configData', jsonData)
        // 预览图
        if (this.isPreview) {
          const previewSrc = await this.getMyKonvaPreviewSrc()
          this.$emit('update:preview', previewSrc) //更新预览图
        } // 测试阶段先不保存
        // 手机端底图
        if (this.isMobilePreview && !this.custom) {
          const mobilePreviewSrc = await this.getMyKonvaMobilePreviewSrc()
          this.$emit('update:mobilePreview', mobilePreviewSrc) //更新手机端底图
        }
        // 完毕
        this.saveLoading = false
        this.show = false

        // return // 测试阶段先不保存

        this.$emit('save')
      } catch (err) {
        this.saveLoading = false
        this.$message.error(err.message)
      }
    },
    onBackgroundClick() {
      console.log('清空当前激活的形状id')
      this.active_element_id = ''
    },
    //图形点击事件
    onShapeClick({ attrs }) {
      const { classify, id } = attrs
      console.log('点击舞台/图形', attrs)
      //切换tab
      classify && (this.activeName = classify)
      //滑动定位
      if (id) {
        this.active_element_id = id

        this.$nextTick(() => {
          this.hashScrollPostion(`myKonva-${id}`)
          //聚焦
          const chindItem = this.$refs[`myKonva-${id}`][0]
          chindItem && chindItem.focus && chindItem.focus()
        })
      } else {
        console.log('点了啥，怎么没有id？', attrs)
      }
    },
    //hash滑动定位
    hashScrollPostion(id) {
      const dom = document.getElementById(id)
      dom && dom.scrollIntoView({ behavior: 'smooth' })
    },
  },
  components: {
    RightImageItem,
    RightFormItem,
  },
}
</script>

<style lang="scss" scoped>
.my-konva {
  .content {
    height: 800px;
    // 左侧
    @import './scss/left.scss';

    //右侧
    @import './scss/right.scss';
  }
}
</style>
