/*
 * @Author: mulingyuer
 * @Date: 2021-06-30 10:12:43
 * @LastEditTime: 2021-08-19 16:13:55
 * @LastEditors: mulingyuer
 * @Description:内容配置
 * @FilePath: \super-admin-vue\src\base\components\MyKonva\data\content.js
 * 怎么可能会有bug！！！
 */


// 固定的变量
export const fixedVariable = [
  // 姓名
  {
    className: "text",
    attrs: {
      classify: "fixedVariable",
      name: "text",
      id: "body_user_name",
      title: "会员名称",
      x: 200,
      y: 40,
      text: "#员工姓名#",
      fontSize: 16,
      myFontSize: 16,
      letterSpacing: 1,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#000000",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
  // 颁发单位
  {
    className: "text",
    attrs: {
      classify: "fixedVariable",
      name: "text",
      id: "footer_company_name",
      title: "颁发单位",
      x: 200,
      y: 60,
      text: "某某有限公司",
      fontSize: 12,
      myFontSize: 12,
      letterSpacing: 1,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#000000",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
  //颁发时间
  {
    className: "text",
    attrs: {
      classify: "fixedVariable",
      name: "text",
      id: "footer_date_issue",
      title: "颁发时间",
      x: 200,
      y: 80,
      text: "2021-03-10",
      fontSize: 12,
      myFontSize: 12,
      letterSpacing: 1,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#000000",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
  // 有效日期
  {
    className: "text",
    attrs: {
      classify: "fixedVariable",
      name: "text",
      id: "footer_valid_date",
      title: "有效日期",
      x: 200,
      y: 100,
      text: "2022-05-20",
      fontSize: 12,
      myFontSize: 12,
      letterSpacing: 1,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#000000",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
  {
    className: "text",
    attrs: {
      classify: "fixedVariable",
      name: "text",
      id: "footer_certificate_number",
      title: "证书编号",
      x: 200,
      y: 120,
      text: "证书编号：12345678",
      fontSize: 12,
      myFontSize: 12,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#000000",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true
    },
  },
];

// 自定义文本
export const customVariable = [
  {
    className: "text",
    attrs: {
      classify: "customVariable",
      name: "text",
      title: "文本内容",
      id: "top_title",
      x: 261,
      y: 159,
      text: "荣誉证书",
      fontSize: 56,
      myFontSize: 56,
      fontStyle: "bold",
      letterSpacing: 3,
      fontFamily: "SimSun",
      fill: "#303133",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
  {
    className: "text",
    attrs: {
      classify: "customVariable",
      name: "text",
      title: "文本内容",
      id: "top_divider",
      x: 218,
      y: 214,
      text: "………………………………………………",
      fontSize: 18,
      myFontSize: 18,
      // letterSpacing: 3,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#303133",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
  {
    className: "text",
    attrs: {
      classify: "customVariable",
      name: "text",
      title: "文本内容",
      id: "top_subtitle",
      x: 249,
      y: 235,
      text: "CERTIFICATE OF HONOR",
      fontSize: 16,
      myFontSize: 16,
      letterSpacing: 3,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#303133",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
  {
    className: "text",
    attrs: {
      classify: "customVariable",
      name: "text",
      title: "文本内容",
      id: "body_name_line",
      x: 289,
      y: 300,
      text: "____________",
      fontSize: 16,
      myFontSize: 16,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#979797",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
  {
    className: "text",
    attrs: {
      classify: "customVariable",
      name: "text",
      title: "文本内容",
      id: "body_name_appellation",
      x: 406,
      y: 296,
      text: "先生/女士",
      fontSize: 16,
      myFontSize: 16,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#303133",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
  {
    className: "text",
    attrs: {
      classify: "customVariable",
      name: "text",
      title: "文本内容",
      id: "body_company_name",
      x: 228,
      y: 329,
      text: "荣获2019年某某某某某某某某某有限公司",
      fontSize: 16,
      myFontSize: 16,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#303133",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
  {
    className: "text",
    attrs: {
      classify: "customVariable",
      name: "text",
      title: "文本内容",
      id: "body_honor",
      x: 257,
      y: 371,
      text: "年度最佳销售奖",
      fontSize: 32,
      myFontSize: 32,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#000000",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
  {
    className: "text",
    attrs: {
      classify: "customVariable",
      name: "text",
      title: "文本内容",
      id: "body_honor_info",
      x: 300,
      y: 434,
      text: "特发此证，以兹鼓励",
      fontSize: 16,
      myFontSize: 16,
      letterSpacing: 1,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#000000",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
  {
    className: "text",
    attrs: {
      classify: "customVariable",
      name: "text",
      title: "文本内容",
      id: "footer_company_name_info",
      x: 230,
      y: 562,
      text: "颁发单位",
      fontSize: 12,
      myFontSize: 12,
      letterSpacing: 1,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#565F64",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
  {
    className: "text",
    attrs: {
      classify: "customVariable",
      name: "text",
      title: "文本内容",
      id: "footer_date_issue_info",
      x: 353,
      y: 562,
      text: "签发日期",
      fontSize: 12,
      myFontSize: 12,
      letterSpacing: 1,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#565F64",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
  {
    className: "text",
    attrs: {
      classify: "customVariable",
      name: "text",
      title: "文本内容",
      id: "footer_valid_date_info",
      x: 476,
      y: 562,
      text: "有效期至",
      fontSize: 12,
      myFontSize: 12,
      letterSpacing: 1,
      fontStyle: "bold",
      fontFamily: "SimSun",
      fill: "#565F64",
      draggable: true,
      transformer: true,
      keepRatio: true,
      visible: true,
    },
  },
]

// 默认自定义文本数据
export const defaultCustomData = () => ({
  className: "text",
  attrs: {
    classify: "customVariable",
    name: "text",
    id: "defaultCustomData",
    title: "文本内容",
    x: 40,
    y: 40,
    text: "请输入自定义文本",
    fontSize: 16,
    myFontSize: 16,
    fontStyle: "bold",
    fontFamily: "SimSun",
    fill: "#000000",
    draggable: true,
    transformer: true,
    keepRatio: true,
    visible: true,
  },
});

export const defaultImageData = () => ({
  className: 'image',
  attrs: {
    classify: 'customImage',
    name: 'image',
    id: 'defaultImageData',
    title: '图片内容',
    x: 40,
    y: 40,
    src: '',
    draggable: true,
    transformer: true,
    keepRatio: true,
    visible: true
  }
})
